import React from 'react'
import { navigate } from 'gatsby'
import { useQuery } from '@apollo/client'
import { CacheUser, ResponseType } from '@types'
import { user as graphqlUser, store as graphqlStore } from '@graphql'
import { AccountLayout } from '@layouts'
import { Button } from '@elements'

import Wallet from '@images/wallet.svg'
import BackArrowImage from '@images/back-arrow.svg'
import Check from '@images/check.svg'
import ArrowRight from '@images/arrow-right.svg'
import { useWindowSize } from '@hooks'

import '../../../../layouts/AccountDetailsLayout/AccountDetailsLayout.scss'
import '../../account.scss'

interface StartSellingPageProps {}
type StripeAccountUrlResponseType = {
  result: {
    created: number
    expires_at: number
    object: string
    url: string
  }
}

//TODO delete not used page

const StartSellingPage: React.FC<StartSellingPageProps> = props => {
  const { toRender: isMobileScreen } = useWindowSize(['mobile', 'landscape'])
  const { data: userData, loading } = useQuery<ResponseType<CacheUser>>(graphqlUser.FetchCurrentUser, {
    fetchPolicy: 'cache-only'
  })
  const { data: stripeAccountUrlData } = useQuery<ResponseType<StripeAccountUrlResponseType>>(
    graphqlStore.GetStripeAccountUrl,
    { fetchPolicy: 'cache-first', variables: { redirectUrl: '/account/store' } }
  )

  const user = userData && userData.res
  const stripeAccountUrl = stripeAccountUrlData?.res?.result?.url

  if (!loading && !user) navigate('/home/')
  if (!user && !stripeAccountUrl) return null

  return (
    <AccountLayout pageClassName={'store'} hideLayout={isMobileScreen}>
      {user && (
        <>
          <div className={'text-20 account-details-layout-title'}>
            <div
              className={'account-details-layout-title-back account-store-start-selling-title-back'}
              onClick={async () => await navigate('/account/store')}
            >
              <BackArrowImage />
              <div>Store</div>
            </div>
          </div>
          <div className={'text-26 uppercase account-store-start-selling-title'}>How to get started</div>
          <div className={'text-18 account-store-start-selling-subtitle'}>
            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim
            velit mollit.
          </div>
          <div className={'account-store-start-selling-payments'}>
            <div>
              <div className={'text-18 account-store-start-selling-payments-title'}>
                <Check />
                <div>Payout Method</div>
              </div>
              <div className={'account-store-start-selling-payments-subtitle'}>
                Set up your default payout method by clicking <b>“Add Payout Method”</b>
              </div>
              <ArrowRight className={'start-selling-arrow'} />
            </div>
            <div>
              <div className={'text-18 account-store-start-selling-payments-title'}>
                <Wallet />
                <div>Payment</div>
              </div>
              <div className={'account-store-start-selling-payments-subtitle'}>
                Your payments will be automatically transferred to your default payout method
              </div>
            </div>
          </div>
          <div className={'account-store-start-selling-button'}>
            <a href={stripeAccountUrl}>
              <Button onClick={() => null}>Add Payout Method</Button>
            </a>
          </div>
        </>
      )}
    </AccountLayout>
  )
}

export default StartSellingPage
